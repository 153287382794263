import axios from "axios";
import { baseURL } from "../config/config";

export const saveReclamo = async ({
  paciente_name,
  paciente_phone,
  role_user_name,
  reclamo,
  estado,
}) => {
  return await axios
    .post(`${baseURL}/saveReclamos`, {
      paciente_name,
      paciente_phone,
      role_user_name,
      reclamo,
      estado,
    })
    .then((response) => {
      const { data } = response;
      return data;
    });
};
