import Winner from "../../assets/animations/rating/winner.json";
import MuyMalo from "../../assets/animations/rating/emoji4.json";
import Malo from "../../assets/animations/rating/emoji1.json";
import Regular from "../../assets/animations/rating/emoji3.json";
import Bueno from "../../assets/animations/rating/emoji5.json";
import Excelente from "../../assets/animations/rating/emoji2.json";
import Loader from "../../assets/animations/loaders/bouncing.json";
import Champion from "../../assets/animations/rating/winner.json";
import SadGuy from "../../assets/animations/result/sad_guy.json";
import SadFox from "../../assets/animations/result/sad_fox.json";
import SadBouncing from "../../assets/animations/result/sad_bouncing.json";
import Thinking from "../../assets/animations/thinking.json";
import { styled } from "@mui/material/styles";
import { Box, Button, Switch, Typography } from "@mui/material";

//* Animation Settings

export const winnerCupSettings = {
  loop: true,
  autoplay: true,
  animationData: Winner,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const loaderSettings = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const championSettings = {
  loop: true,
  autoplay: true,
  animationData: Champion,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const sadguySettings = {
  loop: true,
  autoplay: true,
  animationData: SadGuy,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const sadfoxSettings = {
  loop: true,
  autoplay: true,
  animationData: SadFox,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const sadBouncing = {
  loop: true,
  autoplay: true,
  animationData: SadBouncing,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const thinkingSettings = {
  loop: true,
  autoplay: true,
  animationData: Thinking,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const emoji1Settings = {
  loop: true,
  autoplay: true,
  animationData: MuyMalo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const emoji2Settings = {
  loop: true,
  autoplay: true,
  animationData: Malo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const emoji3Settings = {
  loop: true,
  autoplay: true,
  animationData: Regular,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const emoji4Settings = {
  loop: true,
  autoplay: true,
  animationData: Bueno,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const emoji5Settings = {
  loop: true,
  autoplay: true,
  animationData: Excelente,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

//* MUI Components

export const Calificaciones = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "40px",
}));

export const Header = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  padding: "10px 80px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: "#333",
  fontSize: "50px",
  fontFamily: "poppins",
  fontWeight: "600",
  textAlign: "center",
  lineHeight: "4.5rem",
  textTransform: "uppercase",
}));

export const ChooseTitle = styled(Typography)(({ theme }) => ({
  color: "#333",
  padding: "0 50px",
  fontSize: "35px",
  fontFamily: "poppins",
  fontWeight: "600",
  textAlign: "center",
  // lineHeight: "4.5rem",
}));

export const UserToRate = styled(Typography)(({ theme }) => ({
  fontFamily: "nunito sans",
  fontWeight: "800",
  fontSize: "40px",
  textTransform: "uppercase",
  color: "#713C8F",
  textAlign: "center",
}));

export const InputTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "nunito sans",
  fontWeight: "800",
  fontSize: "35px",
  textTransform: "uppercase",
  color: "#1976D2",
  textAlign: "center",
}));

export const EmojiWrapper = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

export const RatingContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const EmojiText = styled(Typography)(({ theme }) => ({
  color: "#333",
  fontSize: "25px",
  fontFamily: "poppins",
  fontWeight: "600",
}));

export const SuccessText = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  fontWeight: "600",
  fontSize: "45px",
  textTransform: "uppercase",
  color: "#5B60AA",
  textAlign: "center",
  lineHeight: "4rem",
}));

export const TimeOutText = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  fontWeight: "500",
  fontSize: "22px",
  textTransform: "uppercase",
  color: "#333",
  textAlign: "center",
  // lineHeight: "4rem",
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "0 150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
}));

export const YesButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  backgroundColor: "#057ded",
  ":hover": {
    backgroundColor: "#0282fa",
  },
}));

export const NoButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  backgroundColor: "#f51845",
  ":hover": {
    backgroundColor: "#fc2652",
  },
}));

export const SendData = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  backgroundColor: "#057ded",
  ":hover": {
    backgroundColor: "#0282fa",
  },
  color: "white",
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const TextLoading = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  color: "#333",
  fontFamily: "poppins",
  fontWeight: "700",
  textTransform: "uppercase",
}));
// ---------------------------------------------------------------------------
export const Control = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",

  padding: "5px",
}));
export const Contenido = styled(Box)(({ theme }, display) => ({
  width: "100%",
  display: display,
  flexWrap: "wrap",
}));
export const Inner = styled(Box)(({ theme }) => ({
  margin: "5px",
  flex: 1,
  //minWidth: "200px",
  // background: "#ddd",
  padding: "5px",
  border: "5px solid rgba(0, 0, 0, 0.04)",
}));
export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
