import {
  emoji1Settings,
  emoji2Settings,
  emoji3Settings,
  emoji4Settings,
  emoji5Settings,
} from "./Styles";

export const scoreValues = [
  {
    score: 1,
    emojiWidth: 180,
    emojiSettings: emoji1Settings,
  },
  {
    score: 2,
    emojiWidth: 200,
    emojiSettings: emoji2Settings,
  },
  {
    score: 3,
    emojiWidth: 185,
    emojiSettings: emoji3Settings,
  },
  {
    score: 4,
    emojiWidth: 225,
    emojiSettings: emoji4Settings,
  },
  {
    score: 5,
    emojiWidth: 250,
    emojiSettings: emoji5Settings,
  },
];
