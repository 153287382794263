import React, { useEffect } from "react";
import Lottie from "react-lottie";
import {
  feedbackSettings,
  spinnerSettings,
  BoxWrapper,
  TitleContainer,
  LoaderText,
  BuzonSugerencias,
  Title,
} from "./Styles";
import ModalWaiting from "../calificacion/ModalWaiting";
import { socketServer } from "../../config/config";
import { useStateContext } from "../../contexts/buzonContext";

import io from "socket.io-client";

const socket = io(socketServer);

const Buzon = () => {
  const {
    saveDataBuzonEspera,
    modalStaff,
    modalGeneral,
    modalWaiting,
    resData,
    setResData,
    toggleWaiting,
    setModalWaiting,
    toggleStaff,
    toggleGeneral,
  } = useStateContext();
  const verificarEstadoModal = (restOfData) => {
    const estadoModal = localStorage.getItem("modalBuzon");
    if (estadoModal === "INACTIVO") {
      setResData({
        modalOpen: restOfData.modalOpen,
        paciente_id: restOfData.paciente_id,
        role_user_id: restOfData.role_user_id,
        staff_id: restOfData.staff_id,
        cita_id: restOfData.cita,
        sucursal: restOfData.sucursal,
        nombrepaciente: restOfData.nombrePac,
        estado: 0,
      });
      setModalWaiting(restOfData.modalOpen);
      saveDataBuzonEspera(restOfData);
      console.log("activando modal");
      localStorage.setItem("modalBuzon", "ACTIVO");
    } else {
      saveDataBuzonEspera(restOfData);
      console.log("si hay un modal activo");
    }
  };
  useEffect(() => {
    socket.on("recibirModal", (restOfData) => {
      verificarEstadoModal(restOfData);
      // setResData({
      //   modalOpen: restOfData.modalOpen,
      //   paciente_id: restOfData.paciente_id,
      //   role_user_id: restOfData.role_user_id,
      //   staff_id: restOfData.staff_id,
      //   cita_id: restOfData.cita,
      //   sucursal: restOfData.sucursal,
      //   nombrepaciente: restOfData.nombrePac,
      //   estado: 0,
      // });
      // setModalWaiting(restOfData.modalOpen);
    });
    socket.emit("new-user", {
      idUser: 1000,
      name: "BUZON FAIRDENT",
      idSucursal: 1,
      IdRoles: 0,
      estado: "ACTIVO",
      color: "#ff8a65",
    });
    socket.on("get-user", (data) => {
      console.log("escuchando peticiond el servidor", data);
      socket.emit("new-user", {
        idUser: 1000,
        name: "BUZON FAIRDENT",
        idSucursal: 1,
        IdRoles: 0,
        estado: "ACTIVO",
        color: "#ff8a65",
      });
    });
  });

  return (
    <BuzonSugerencias>
      <BoxWrapper>
        <Lottie options={feedbackSettings} width={400} />
        <TitleContainer>
          <Title>TU OPINION NOS IMPORTA</Title>
        </TitleContainer>
      </BoxWrapper>
      <BoxWrapper>
        <Lottie options={spinnerSettings} width={400} />
        <LoaderText>Esperando respuesta...</LoaderText>
      </BoxWrapper>
      {modalWaiting === true && (
        <ModalWaiting
          modalWaiting={modalWaiting}
          modalStaff={modalStaff}
          toggleStaff={toggleStaff}
          toggleGeneral={toggleGeneral}
          modalGeneral={modalGeneral}
          toggleWaiting={toggleWaiting}
          resData={resData}
        />
      )}
    </BuzonSugerencias>
  );
};

export default Buzon;
