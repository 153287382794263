import { createContext, useState, useContext } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [modalStaff, setModalStaff] = useState(false);
  const [modalGeneral, setModalGeneral] = useState(false);
  const [modalWaiting, setModalWaiting] = useState(false);
  const [resData, setResData] = useState({
    modalOpen: false,
    paciente_id: null,
    role_user_id: null,
    staff_id: null,
    cita_id: null,
    sucursal: null,
    nombrepaciente: null,
    estado: null,
  });
  const toggleWaiting = () => setModalWaiting(!modalWaiting);
  const toggleStaff = () => setModalStaff(!modalStaff);
  const toggleGeneral = () => setModalGeneral(!modalGeneral);

  // obtengo los datos de espera del localstorage
  const dataEsperaLocal = JSON.parse(localStorage.getItem("buzonEspera"));

  // inicializo el valor de los datos de espera en un usestate, caso contratio array vacio
  const [dataEspera, setDataEspera] = useState(dataEsperaLocal ?? []);

  // guardo los datos enviados del cliente al servidor buzon
  const saveDataBuzonEspera = async (data) => {
    console.log("saveDataBuzonEspera", data);
    const newData = {
      ...data,
    };
    const estadoverificacion = await verificarDataByListaEspera(data.cita ?? 0);
    if (estadoverificacion === false) {
      setDataEspera([...dataEspera, newData]);
    }
  };

  // verificar si la nueva data ya existe en la lista de espera
  const verificarDataByListaEspera = (idCita) => {
    // Utilizar el método some para verificar si algún objeto tiene el mismo id
    return dataEspera.some((objeto) => objeto.cita === idCita);
  };

  // actualiza el localstorage de los datos en espera buzon
  const saveDataLocalStorage = () => {
    localStorage.setItem("buzonEspera", JSON.stringify(dataEspera));
  };

  // actualizo toda la data con la razon de no repetir en la lista de espera
  const deleteDataBuzonEspera = (idCita) => {
    // Filtrar el array para excluir el objeto con el ID dado
    const nuevoArray = dataEspera.filter((objeto) => objeto.cita !== idCita);
    // Actualizar el estado con el nuevo array
    setDataEspera(nuevoArray);

    // const index = dataEspera.findIndex((x) => x.cita === idCita);
    // dataEspera.splice(index, 1);
    // setDataEspera([...dataEspera]);

    saveDataLocalStorage();
    verificarArrayEspera();
  };

  const deleteDuplicateDataEspera = () => {
    // Función para eliminar objetos duplicados por la propiedad 'id'
    const eliminarDuplicados = (arr, prop) =>
      arr.filter(
        (obj, index, array) =>
          array.findIndex((o) => o[prop] === obj[prop]) === index
      );

    // Actualizar el estado con el array sin duplicados
    setDataEspera(eliminarDuplicados(dataEspera, "cita"));
  };

  const verificarArrayEspera = () => {
    console.log("verificarArrayEspera", dataEspera);
    if (dataEspera.length === 0) {
      localStorage.setItem("modalBuzon", "INACTIVO");
    }
  };
  return (
    <StateContext.Provider
      value={{
        modalStaff,
        modalGeneral,
        modalWaiting,
        resData,
        setResData,
        toggleWaiting,
        toggleStaff,
        toggleGeneral,
        dataEspera,
        saveDataLocalStorage,
        setDataEspera,
        saveDataBuzonEspera,
        deleteDataBuzonEspera,
        dataEsperaLocal,
        setModalWaiting,
        deleteDuplicateDataEspera,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
