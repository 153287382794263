import Modal from "../../components/modal/Modal";
import React, { useState, useEffect } from "react";
import { Champion } from "../../components/animations/Champion";
import { SadFox } from "../../components/animations/SadFox";
import { SuccessText, TimeOutText } from "./Styles";
import { Box } from "@mui/material";

const ModalSuccess = ({
  modalSuccess,
  closeModalSuccess,
  toggleWaiting,
  scoreStaff,
}) => {
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
      toggleWaiting();
      closeModalSuccess();
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <>
      <Modal size="md" isOpen={modalSuccess} title="TU OPINION NOS IMPORTA">
        <Box sx={{ margin: "40px 0" }}>
          {scoreStaff <= 3 && (
            <>
              <Box sx={{ marginBottom: "-10px", padding: "0 40px" }}>
                <SuccessText>
                  GRACIAS POR CALIFICARNOS, PROMETEMOS MEJORAR NUESTRO SERVICIO
                </SuccessText>
              </Box>
              <SadFox />
            </>
          )}
          {scoreStaff > 3 && (
            <>
              <Box sx={{ marginBottom: "-50px" }}>
                <SuccessText>GRACIAS POR COMPARTIR TU EXPERIENCIA</SuccessText>
              </Box>
              <Champion />
            </>
          )}
          <Box
            sx={
              scoreStaff > 3
                ? { marginTop: "40px", padding: "0 150px" }
                : { marginTop: "0", padding: "0 150px" }
            }
          >
            <TimeOutText>
              Esta ventana se cerrará automaticamente en {timeLeft} segundos
            </TimeOutText>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ModalSuccess;
