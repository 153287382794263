import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
};

const item = {
  padding: "30px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const navText = {
  color: "#fff",
  textTransform: "capitalize",
  fontSize: "20px",
  fontFamily: "poppins",
};

const NavHome = () => {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box sx={{ ...styles }}>
          <Link to="/">
            <Box sx={{ ...item }}>
              <Typography sx={{ ...navText }}>INICIO</Typography>
            </Box>
          </Link>
          <Link to="/buzon">
            <Box sx={{ ...item }}>
              <Typography sx={{ ...navText }}>BUZON DE SUGERENCIAS</Typography>
            </Box>
          </Link>
        </Box>
      </Stack>
    </>
  );
};

export default NavHome;
