import axios from "axios";
import { baseURL } from "../config/config";

export const saveSugerencia = async ({
  paciente_name,
  paciente_phone,
  sugerencia,
  estado,
}) => {
  return await axios
    .post(`${baseURL}/saveSugerencias`, {
      paciente_name,
      paciente_phone,
      sugerencia,
      estado,
    })
    .then((response) => {
      const { data } = response;
      return data;
    });
};
