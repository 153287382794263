import { Container, Box } from "@mui/material";
import React from "react";
import NavHome from "../../components/navbar/NavHome";
import Home from "./Home";

const styles = {
  background:
    "linear-gradient(160deg, rgba(82,18,90,1) 35%, rgba(194,48,181,1) 100%)",
  width: "100%",
  minHeight: "100vh",
};

const Layout = () => {
  return (
    <Box style={{ ...styles }}>
      <Container>
        <NavHome />
        <Home />
      </Container>
    </Box>
  );
};

export default Layout;
