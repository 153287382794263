import axios from "axios";
import { baseURL } from "../config/config";

export const saveBuzonData = async ({
  calif_user,
  calif_general,
  paciente_name,
  paciente_phone,
  role_user_id,
  role_user_name,
  staffuser_name,
  cita_id,
  staff_id,
  estado,
  screenshot,
}) => {
  return await axios
    .post(`${baseURL}/saveBuzonData`, {
      calif_user,
      calif_general,
      paciente_name,
      paciente_phone,
      role_user_id,
      role_user_name,
      staffuser_name,
      cita_id,
      staff_id,
      estado,
      screenshot,
    })
    .then((response) => {
      const { data } = response;
      return data;
    });
};

export const getAllNames = async ({ paciente_id, staff_id, role_user_id }) => {
  return await axios
    .post(`${baseURL}/getAllNames`, { paciente_id, staff_id, role_user_id })
    .then((response) => {
      const { data } = response;
      return data;
    });
};
