import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import ModalWaiting from "../calificacion/ModalWaiting";
import { useStateContext } from "../../contexts/buzonContext";

export default function TablaBuzonEspera() {
  const {
    dataEspera,
    resData,
    setResData,
    modalStaff,
    modalGeneral,
    modalWaiting,
    toggleWaiting,
    toggleStaff,
    toggleGeneral,
    setModalWaiting,
    deleteDataBuzonEspera,
    saveDataLocalStorage,
    deleteDuplicateDataEspera,
  } = useStateContext();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataBuzonEspera, setDataBuzonEspera] = React.useState([]);
  const [loadingList, setLoadingList] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const abrirCalificacion = (row) => {
    setResData({
      modalOpen: row.modalOpen,
      paciente_id: row.paciente_id,
      role_user_id: row.role_user_id,
      staff_id: row.staff_id,
      cita_id: row.cita,
      sucursal: row.sucursal,
      nombrepaciente: row.nombrePac,
      estado: 0,
    });
    setModalWaiting(row.modalOpen);
  };

  const updateListEspera = async () => {
    setLoadingList(true);
    console.log("actualizar lista");
    deleteDuplicateDataEspera();
    setLoadingList(false);
  };

  React.useEffect(() => {
    setDataBuzonEspera(dataEspera);
    console.log("actualizando", dataEspera);
    saveDataLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEspera]);

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                PACIENTES EN ESPERA PARA CALIFICACION
              </TableCell>
              <TableCell align="center" colSpan={1}>
                <IconButton color="info" onClick={updateListEspera}>
                  <UpdateIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">N°</TableCell>
              <TableCell align="center">Nombre Paciente</TableCell>
              <TableCell align="center">CITA ID</TableCell>
              <TableCell align="center">Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {loadingList ? (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan="3"
                    align="center"
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : dataBuzonEspera?.length === 0 ? (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan="3"
                    align="center"
                  >
                    No hay datos
                  </TableCell>
                </TableRow>
              ) : (
                dataBuzonEspera?.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.nombrePac}
                    </TableCell>
                    <TableCell align="center">{row.cita}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => abrirCalificacion(row)}
                      >
                        CALIFICAR
                      </Button>
                      <IconButton
                        color="error"
                        component="label"
                        onClick={() => {
                          deleteDataBuzonEspera(row.cita);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {modalWaiting === true && (
        <ModalWaiting
          modalWaiting={modalWaiting}
          modalStaff={modalStaff}
          toggleStaff={toggleStaff}
          toggleGeneral={toggleGeneral}
          modalGeneral={modalGeneral}
          toggleWaiting={toggleWaiting}
          resData={resData}
        />
      )}
    </Paper>
  );
}
