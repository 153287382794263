import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import Lottie from "react-lottie";
import ModalCalificacionGeneral from "./ModalCalificacionGeneral";
import {
  Calificaciones,
  Header,
  TitleContainer,
  Title,
  UserToRate,
  RatingContainer,
  EmojiWrapper,
  EmojiText,
} from "./Styles";
import { scoreValues } from "./ScoreValues";
import { Box } from "@mui/material";
import { Bouncing as Loader } from "../../components/loaders/Bouncing";

const scoreTitle = ["Muy Malo", "Malo", "Regular", "Bueno", "Excelente"];

const ModalCalificacionStaff = ({
  modalStaff,
  toggleStaff,
  toggleGeneral,
  modalGeneral,
  toggleWaiting,
  resData,
  pac_name,
  role_name,
  staff_name,
}) => {
  const [loading, setLoading] = useState(false);
  const [scoreStaff, setScoreStaff] = useState(null);

  const showLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const openModalCalGeneral = () => {
    showLoader();
    setTimeout(() => {
      toggleStaff();
      toggleGeneral();
    }, 1100);
  };

  return (
    <>
      <Modal title="TU OPINION NOS IMPORTA" size="xl" isOpen={modalStaff}>
        <Calificaciones>
          {loading && <Loader />}
          {!loading && (
            <>
              <Header>
                <TitleContainer>
                  <Title>
                    HOLA&nbsp;
                    <span style={{ color: "#1a4b99" }}>
                      {pac_name.pac_name}
                    </span>
                    , POR FAVOR CALIFICA LA ATENCION RECIBIDA POR TU&nbsp;
                    <span style={{ color: "#1a4b99" }}>{role_name}:</span>
                  </Title>
                </TitleContainer>
                <TitleContainer>
                  <UserToRate>
                    {staff_name.nombres}&nbsp;{staff_name.apellidos}
                  </UserToRate>
                </TitleContainer>
              </Header>
              <RatingContainer>
                {scoreValues.map((item, idx) => {
                  return (
                    <EmojiWrapper key={idx}>
                      <Box
                        onClick={() => {
                          openModalCalGeneral(item.score);
                          setScoreStaff(item.score);
                        }}
                      >
                        <Lottie
                          options={item.emojiSettings}
                          width={item.emojiWidth}
                        />
                      </Box>
                    </EmojiWrapper>
                  );
                })}
              </RatingContainer>
              <RatingContainer>
                {scoreTitle.map((title, idx) => {
                  return (
                    <EmojiWrapper key={idx}>
                      <EmojiText>{title}</EmojiText>
                    </EmojiWrapper>
                  );
                })}
              </RatingContainer>
            </>
          )}
        </Calificaciones>
      </Modal>
      <ModalCalificacionGeneral
        toggleWaiting={toggleWaiting}
        modalGeneral={modalGeneral}
        toggleGeneral={toggleGeneral}
        scoreStaff={scoreStaff}
        resData={resData}
        pac_name={pac_name}
        role_name={role_name}
        staff_name={staff_name}
      />
    </>
  );
};

export default ModalCalificacionStaff;
