import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../../assets/logos/logo.svg";

const BoxContainer = {
  width: "100%",
  height: "80px",
  padding: "10px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const title = {
  fontFamily: "poppins",
  fontSize: "25px",
  fontWeight: "400",
  color: "gray",
};

const NavBuzon = () => {
  return (
    <>
      <Box sx={{ ...BoxContainer }}>
        <Box>
          <Typography sx={{ ...title }}>Buzon de Sugerencias</Typography>
        </Box>
        <Box>
          <img src={Logo} alt="" width="150px" />
        </Box>
      </Box>
    </>
  );
};

export default NavBuzon;
