import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/home/Layout";
import Buzon from "./views/buzon/Layout";
// import Reclamos from "./views/reclamos/Reclamos";
// import Sugerencias from "./views/sugerencias/Sugerencias";
import PageNotFound from "./views/PageNotFound";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buzon" element={<Buzon />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
