import React, { useEffect, useState } from "react";
import ModalCalificacionStaff from "./ModalCalificacionStaff";
import Modal from "../../components/modal/Modal";
import { Bouncing as Loader } from "../../components/loaders/Bouncing";
import { Box } from "@mui/material";
import { getAllNames } from "../../services/buzon";
import { TextContainer, TextLoading } from "./Styles";

const ModalWaiting = ({
  modalStaff,
  modalWaiting,
  toggleStaff,
  toggleGeneral,
  modalGeneral,
  toggleWaiting,
  resData,
}) => {
  const [pac_name, setpac_name] = useState([]);
  const [role_name, setrole_name] = useState([]);
  const [staff_name, setstaff_name] = useState([]);

  const loadValues = () => {
    getAllNames(resData)
      .then((res) => {
        if (res.success === true) {
          const [data_pac] = res.paciente_name;
          setpac_name(data_pac);
          const [data_role] = res.role_user_name;
          setrole_name(data_role.name);
          const [data_staff] = res.staffuser_name;
          setstaff_name(data_staff);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeWaitingModal = () => {
    loadValues();
    setTimeout(() => {
      toggleStaff();
    }, 2000);
  };

  useEffect(() => {
    closeWaitingModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = true;

  return (
    <>
      <Modal title="TU OPINION NOS IMPORTA" size="md" isOpen={modalWaiting}>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <>
              <Loader />
              <TextContainer>
                <TextLoading>Cargando datos...</TextLoading>
              </TextContainer>
            </>
          )}
        </Box>
      </Modal>
      <ModalCalificacionStaff
        modalStaff={modalStaff}
        modalWaiting={modalWaiting}
        toggleStaff={toggleStaff}
        toggleGeneral={toggleGeneral}
        modalGeneral={modalGeneral}
        toggleWaiting={toggleWaiting}
        resData={resData}
        pac_name={pac_name}
        role_name={role_name}
        staff_name={staff_name}
      />
    </>
  );
};

export default ModalWaiting;
