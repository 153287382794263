import React, { useState, useRef, useEffect } from "react";
import Modal from "../../components/modal/Modal";
import ModalSuccess from "./ModalSuccess";
import Tab from "../../components/tab/Tab";
import {
  Calificaciones,
  Header,
  TitleContainer,
  Title,
  UserToRate,
  RatingContainer,
  EmojiWrapper,
  EmojiText,
  ButtonWrapper,
  ButtonsWrapper,
  ChooseTitle,
  YesButton,
  NoButton,
} from "./Styles";
import { AiOutlineStar } from "react-icons/ai";
import { Bouncing as Loader } from "../../components/loaders/Bouncing";
import { Box, TextField } from "@mui/material";
import { saveBuzonData } from "../../services/buzon";
import { saveSugerencia } from "../../services/sugerencias";
import { saveReclamo } from "../../services/reclamos";
import { Thinking } from "../../components/animations/Thinking";
import "./styles.css";
import { socketServer } from "../../config/config";
import { useStateContext } from "../../contexts/buzonContext";

import io from "socket.io-client";
import Webcam from "react-webcam";

const socket = io(socketServer);

const scoreTitle = ["Muy Malo", "Malo", "Regular", "Bueno", "Excelente"];

const ModalCalificacionGeneral = ({
  toggleWaiting,
  modalGeneral,
  toggleGeneral,
  resData,
  scoreStaff,
  pac_name,
  role_name,
  staff_name,
}) => {
  const { deleteDataBuzonEspera } = useStateContext();
  const [loading, setLoading] = useState(false);
  // const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [inputModal, setInputModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const webcamRef = useRef(null);

  const toggleConfirm = () => setConfirmModal(!confirmModal);
  const toggleInput = () => setInputModal(!inputModal);
  const showModalSuccess = () => setModalSuccess(true);
  const closeModalSuccess = () => setModalSuccess(false);

  const initialValues = {
    calif_general: null,
    paciente_name: "",
    paciente_phone: null,
    role_user_name: "",
    staffuser_name: "",
  };

  const prevInitialValues = {
    calif_general: null,
    paciente_name: "",
    paciente_phone: null,
    role_user_name: "",
    staffuser_name: "",
  };

  const [values, setValues] = useState(initialValues);
  const [prevValues, setPrevValues] = useState(prevInitialValues);

  const handleChange = (value) => {
    setValues(value);
  };

  const staff_fullname = staff_name.nombres + " " + staff_name.apellidos;
  const pac_fullname = pac_name.pac_name + " " + pac_name.pac_lastname;

  const storeSugerencia = async () => {
    const sugerenciaData = {
      paciente_name: pac_fullname,
      paciente_phone: pac_name.pac_phone,
      sugerencia: values.sugerencia,
      estado: 0,
    };

    saveSugerencia(sugerenciaData)
      .then((res) => {
        if (res.success === true) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const storeReclamo = async () => {
    const reclamoData = {
      paciente_name: pac_fullname,
      paciente_phone: pac_name.pac_phone,
      role_user_name: staff_fullname,
      reclamo: values.reclamo,
      estado: 0,
    };

    saveReclamo(reclamoData)
      .then((res) => {
        if (res.success === true) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capturePhoto = async () => {
    const photoDataURL = webcamRef.current.getScreenshot();
    return photoDataURL;
  };

  const toggleVisibility = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) console.log("CAMARA ACTIVA", stream);
      if (!stream) console.log("CAMARA INACTIVA", stream);
    } catch (error) {
      console.error("Error al acceder a la cámara:", error);
    }
  };

  //Guardar data sin reclamo o sugerencia
  const SaveCalificacionPrev = async () => {
    const screenshot = await capturePhoto();
    console.log("FOTO CAPTURADA:", screenshot);
    toggleConfirm();
    const idSucursal = resData.sucursal;
    const nombrePaciente = resData.nombrepaciente;
    const finaldata = {
      ...resData,
      ...prevValues,
      modal: true,
      calif_user: scoreStaff,
      screenshot, // Agregar la foto a los datos finales
    };
    console.log(finaldata);

    socket.emit("buzon_data_socket", finaldata);
    socket.emit("parlante-mensaje", {
      type: "mensaje-personalizado",
      payload: {
        data: `${nombrePaciente} gracias por su preferencia, esperamos verte pronto`,
        idSucursal,
      },
    });
    saveBuzonData(finaldata)
      .then((res) => {
        if (res.success === true) {
          console.log("desactivando modal estado", res);
          deleteDataBuzonEspera(res.buzon_data.cita_id);
          showModalSuccess();
          localStorage.setItem("modalBuzon", "INACTIVO");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Guardar data CON reclamo o sugerencia
  const saveCalificacion = () => {
    toggleInput();
    const idSucursal = resData.sucursal;
    const nombrePaciente = resData.nombrepaciente;
    const finaldata = {
      ...resData,
      ...values,
      modal: true,
      calif_user: scoreStaff,
    };
    socket.emit("buzon_data_socket_full", finaldata);
    socket.emit("parlante-mensaje", {
      type: "mensaje-personalizado",
      payload: {
        data: `${nombrePaciente} gracias por su preferencia, esperamos verte pronto`,
        idSucursal,
      },
    });

    saveBuzonData(finaldata)
      .then((res) => {
        if (res.success === true) {
          console.log(res);
          showModalSuccess();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const openModalSuccess = (i) => {
    showLoader();
    setTimeout(() => {
      setValues({
        ...values,
        paciente_name: pac_fullname,
        paciente_phone: pac_name.pac_phone,
        role_user_name: role_name,
        staffuser_name: staff_fullname,
        calif_general: i + 1,
      });
      setPrevValues({
        ...prevValues,
        paciente_name: pac_fullname,
        paciente_phone: pac_name.pac_phone,
        role_user_name: role_name,
        staffuser_name: staff_fullname,
        calif_general: i + 1,
      });
      toggleConfirm();
      toggleGeneral();
    }, 1100);
  };

  const tabs = [
    {
      label: "SUGERENCIAS",
      Component: (
        <>
          <Box sx={{ padding: "10px 20px" }}>
            <TextField
              id="standard-multiline-static"
              type="text"
              multiline
              rows={10}
              placeholder="SUGERENCIAS..."
              fullWidth
              value={values.sugerencia}
              onChange={(event) =>
                handleChange({
                  ...values,
                  sugerencia: event.target.value,
                })
              }
              inputProps={{
                style: {
                  fontSize: "30px",
                  padding: "2px 6px",
                  lineHeight: "40px",
                },
              }}
            />
          </Box>
          <ButtonWrapper sx={{ marginTop: "15px" }}>
            <YesButton
              onClick={() => {
                saveCalificacion();
                storeSugerencia();
              }}
            >
              ENVIAR SUGERENCIA
            </YesButton>
          </ButtonWrapper>
        </>
      ),
    },
    {
      label: "RECLAMOS",
      Component: (
        <>
          <Box sx={{ padding: "10px 20px" }}>
            <TextField
              id="standard-multiline-static"
              type="text"
              multiline
              placeholder="RECLAMOS..."
              rows={10}
              fullWidth
              value={values.reclamo}
              onChange={(event) =>
                handleChange({ ...values, reclamo: event.target.value })
              }
              inputProps={{
                style: {
                  fontSize: "30px",
                  padding: "2px 6px",
                  lineHeight: "40px",
                },
              }}
            />
          </Box>
          <ButtonWrapper sx={{ marginTop: "15px" }}>
            <YesButton
              onClick={() => {
                saveCalificacion();
                storeReclamo();
              }}
            >
              ENVIAR RECLAMO
            </YesButton>
          </ButtonWrapper>
        </>
      ),
    },
  ];
  useEffect(() => {
    toggleVisibility();
  }, []);
  return (
    <>
      <Modal title="TU OPINION NOS IMPORTA" size="xl" isOpen={modalGeneral}>
        <Calificaciones>
          {loading && <Loader />}
          {!loading && (
            <>
              <Header>
                <TitleContainer>
                  <Title>POR FAVOR CALIFICA NUESTRO SERVICIO EN GENERAL:</Title>
                </TitleContainer>
                <TitleContainer>
                  <UserToRate>ESTO NOS AYUDARÁ A MEJORAR</UserToRate>
                </TitleContainer>
              </Header>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ width: "100%", padding: "0 150px" }}>
                  {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;
                    return (
                      <label key={i}>
                        <input
                          type="radio"
                          name="rating"
                          value={ratingValue}
                          onClick={() => openModalSuccess(i)}
                        />
                        <AiOutlineStar
                          className="star"
                          color={ratingValue <= hover ? "#713C8F" : "#BDBDBD"}
                          // color={
                          //   ratingValue <= (hover || rating)
                          //     /? "#713C8F"
                          //     : "#BDBDBD"
                          // }
                          size={200}
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    );
                  })}
                </Box>
              </Box>
              <RatingContainer>
                {scoreTitle.map((title, idx) => {
                  return (
                    <EmojiWrapper key={idx}>
                      <EmojiText>{title}</EmojiText>
                    </EmojiWrapper>
                  );
                })}
              </RatingContainer>
            </>
          )}
        </Calificaciones>
      </Modal>
      <Modal title="TU OPINION NOS IMPORTA" size="md" isOpen={confirmModal}>
        {loading && <Loader />}
        {!loading && (
          <>
            <Header style={{ position: "relative" }}>
              <TitleContainer>
                <ChooseTitle>
                  DESEA AGREGAR ALGUNA SUGERENCIA O RECLAMO?
                </ChooseTitle>
              </TitleContainer>
              <Thinking />

              {/* ocultamos la camara con estilos css */}
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: "user" }}
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  width: "100%",
                  zIndex: "-9999",
                  top: 0,
                  right: 0,
                  left: 0,
                }}
              />
              {/* .----------------------------------. */}
            </Header>

            <ButtonsWrapper>
              <YesButton
                disableElevation
                variant="contained"
                onClick={() => {
                  toggleInput();
                  toggleConfirm();
                }}
              >
                Si, por favor
              </YesButton>

              <NoButton
                variant="contained"
                disableElevation
                onClick={SaveCalificacionPrev}
              >
                NO GRACIAS.
              </NoButton>
            </ButtonsWrapper>
          </>
        )}
      </Modal>
      <Modal title="TU OPINION NOS IMPORTA" size="xl" isOpen={inputModal}>
        {loading && <Loader />}
        {!loading && (
          <>
            <Header>
              <TitleContainer>
                <ChooseTitle>INGRESE SU SUGERENCIA O RECLAMO</ChooseTitle>
              </TitleContainer>
            </Header>
            <Tab tabs={tabs} />
          </>
        )}
      </Modal>
      {modalSuccess === true && (
        <ModalSuccess
          toggleWaiting={toggleWaiting}
          modalSuccess={modalSuccess}
          closeModalSuccess={closeModalSuccess}
          scoreStaff={scoreStaff}
        />
      )}
    </>
  );
};

export default ModalCalificacionGeneral;
