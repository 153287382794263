import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
} from "@mui/material";

const Modal = ({ title, children, isOpen, handleClose, size }) => {
  return (
    <>
      <Dialog fullWidth maxWidth={size} open={isOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Cerrar
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
