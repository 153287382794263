import Feedback from "../../assets/animations/feedback3.json";
import Loader from "../../assets/animations/loaders/loader2.json";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

//* Animation Settings

export const feedbackSettings = {
  loop: true,
  autoplay: true,
  animationData: Feedback,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const spinnerSettings = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const textSwitchSettings = {
  fontFamily: "poppins",
  fontWeight: "200",
  color: "gray",
};

//* MUI Components

export const BuzonSugerencias = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const BoxWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "40px",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  padding: "10px 0",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "nunito sans",
  fontWeight: "600",
  fontSize: "35px",
  textTransform: "uppercase",
  color: "#6C62FF",
}));

export const LoaderText = styled(Typography)(({ theme }) => ({
  fontFamily: "poppins",
  fontWeight: "400",
  fontSize: "25px",
  color: "gray",
}));
