import React from "react";
import Fairdent from "../../assets/images/fairdent.png";
import SOS from "../../assets/images/sos.png";
import Image from "mui-image";
import { Box, Grid } from "@mui/material";

const styles = {
  minHeight: "70vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "0 20px",
};

const Home = () => {
  return (
    <div>
      <Grid container>
        <Grid item lg={5} md={12}>
          <Box sx={{ ...styles }}>
            <Image src={SOS} width="100%" />
          </Box>
        </Grid>
        <Grid item lg={7} md={12}>
          <Box sx={{ ...styles }}>
            <Image src={Fairdent} width="100%" />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
