import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Buzon from "./Buzon";
import BuzonEspera from "./BuzonEspera";
import NavBuzon from "../../components/navbar/NavBuzon";
import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import { useStateContext } from "../../contexts/buzonContext";
import { Contenido, Inner, AntSwitch, Control } from "../calificacion/Styles";
import { textSwitchSettings } from "./Styles";
const Layout = () => {
  const { dataEspera } = useStateContext();
  const [displayBox, setDisplayBox] = useState("row");
  const onChangeSelect = (e) => {
    const checked = e.target.checked;
    setDisplayBox(checked ? "row" : "flex");
  };
  useEffect(() => {
    console.log("inactivo modal", dataEspera);
    localStorage.setItem("modalBuzon", "INACTIVO");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Box style={{ width: "100%" }}>
        <Control>
          <NavBuzon />
        </Control>

        <Control>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography style={textSwitchSettings}>Off</Typography>
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
              onChange={onChangeSelect}
            />
            <Typography style={textSwitchSettings}>On</Typography>
          </Stack>
        </Control>

        <Contenido display={displayBox}>
          <Inner>
            <Buzon />
          </Inner>
          <Inner>
            <BuzonEspera />
          </Inner>
        </Contenido>
      </Box>
    </Container>
  );
};

export default Layout;
